<template>
  <div class="d-flex flex-column">
    <CNavbar expandable="md" color="dark-menu">
      <CToggler in-navbar @click="collapsed = !collapsed"/>
      <CNavbarBrand class="logo-blanco"></CNavbarBrand>
      <CCollapse :show="collapsed" navbar>
        <CNavbarNav
          v-for="levelOne in levelOneOptions"
          :key="levelOne.LevelOneNameJson"
        >
          <CDropdown
            :togglerText="levelOne['LevelOneNameJson'+currentLang]"
            color="dark-menu"
          >
            <CRow class="p-1" :class="childClasses(levelOne.LevelTwoJson)">
              <CCol
                :lg="rowQuantity(levelOne.LevelTwoJson)"
                v-for="levelTwo in levelOne.LevelTwoJson"
                :key="levelTwo.LevelTwoNameJson"
              >
                <CDropdownHeader>{{ levelTwo['LevelTwoNameJson'+currentLang] }}</CDropdownHeader>
                <CDropdownItem
                  v-for="levelThree in levelTwo.LevelTreeJson"
                  :key="levelThree.ModuleName"
                  :class="{ 'active-item': isOptionSelected(levelThree['ModuleName'+currentLang])}"
                  @click="navegateTo(`${levelThree['ModuleName'+currentLang]}`)"
                >
                  {{ levelThree['ModuleName'+currentLang] }}
                </CDropdownItem>
              </CCol>
            </CRow>
          </CDropdown>
        </CNavbarNav>
        <CNavbarNav class="ml-auto">
           <CForm inline class="mr-2">
            <autocomplete
              placeHolder="Search"
              class="mr-sm-2"
              :items="routesOptions"
              v-model="search"
              absolute
              @selected="goToSearch"
            />
          </CForm>
          <CDropdown
            color="dark-menu"
            class="zindex-nav"
            :togglerText="loginName"
            placement="bottom-end"
          >
            <CDropdownItem @click="navegateTo('ChangePassword')">
              {{$t('label.changePass')}}
            </CDropdownItem>
            <CDropdownItem @click="logout">Logout</CDropdownItem>
          </CDropdown>
        </CNavbarNav>
      </CCollapse>
    </CNavbar>
    <div class="c-subheader px-1 position-relative">
      <CBreadcrumbRouter class="border-0 mb-0"/>
      <CRow class="after-breadcrumb justify-content-end px-2">
        <CCol sm="12" lg="3" class="info-margin">
          <CLink
            class="info-link"
            v-c-tooltip="{
              content: $t('label.moduleInfo'),
              placement: 'bottom-end'
            }"
            @click.prevent="toggle"
          >
            <CIcon name="cil-info" class="info-icon"/>
          </CLink>
          <div class="info-collapse" :class="{ 'in': toggleInfo?true:null }">
            <CButtonGroup>
              <CButton
                shape="square"
                color="dark-menu"
                size="sm"
                class="d-flex align-items-center btn-info mx-2"
                target="_blank"
                :href="processRoute"
                :disabled="hasNoFile(processRoute)"
              >
                <CIcon name="cil-cloud-download"/><span class="ml-1">{{$t('label.process')}}</span>
              </CButton>
              <CButton
                shape="square"
                color="dark-menu"
                size="sm"
                class="d-flex align-items-center btn-info"
                target="_blank"
                :href="manualRoute"
                :disabled="hasNoFile(manualRoute)"
              >
                <CIcon name="cil-cloud-download"/><span class="ml-1">MANUAL</span>
              </CButton>
            </CButtonGroup>
          </div>
        </CCol>
        <CCol sm="12" lg="3"
          v-if="navegation && !this.roleSelected?.FgGeneral" 
          class="px-1 justify-content-end">
          <CRow>
            <CCol sm="2" class="px-1 align-self-center">
              <label class="col-form-label col-form-label-sm col-sm-12 col-sm-12 text-right px-0">{{$t('label.yard')}}</label>
            </CCol>
            <CCol sm="10" class="px-1 align-self-center">
              <v-select
                class="select-adjust"
                id="v-select-small"
                :clearable="false"
                :placeholder="$t('label.select')"
                :options="patiosOptions"
                :reduce="option => option.value"
                v-model="$store.state.yardManagement.YardSelected"
              >
                <template #no-options="{}">
                    {{$t('label.noResultsFound')}}
                </template>
             </v-select>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" lg="4" v-show="navegation && ($store.state.yardManagement.yardCollapse == 1 || $store.state.yardManagement.yardCollapse == 2  || $store.state.yardManagement.yardCollapse == 4)" class="justify-content-end mb-2">
          <CRow v-if="!isCoverWareHouse" class="justify-content-end px-1">
            <CCol sm="auto" lg="4" class="px-1 align-self-center">
              <label class="col-form-label col-form-label-sm col-sm-12 col-sm-12 text-right px-0">{{$t('label.container')}}</label>
            </CCol>
            <CCol sm="auto" lg="8" class="px-1 align-self-center form-group form-row align-items-center justify-content-center mb-0">
              <div  class="input-group m-0" style="flex-wrap: nowrap;">
                <v-select
                  class="select-adjust"
                  id="v-select-small"
                  :placeholder="$t('label.select')"
                  :options="ContainerOption"
                  :reduce="option => option.value" 
                  v-model="$store.state.yardManagement.containerSelected"
                >
                  <template #no-options="{}">
                      {{$t('label.noResultsFound')}}
                  </template>
                  <template #option="{ Json, label, value }">
                      {{ label }}
                      <br v-if="value"/>
                      <cite v-if="value&&Json?.VesselName">{{ Json?.VesselName }} - {{ Json?.VoyageArrival }} </cite>
                  </template>
                </v-select>
                <div class="input-group-append">
                  <CButton
                    color="watch"
                    square
                    size="sm"
                    class="rounded"
                    v-c-tooltip="{
                      content: $t('label.refresh'),
                      placement: 'top-end'
                    }" 
                    @click="Refreshcontainers()"
                    style="padding: 0.15rem 0.4rem;"
                  >
                    <CIcon name="cil-reload"/>
                  </CButton>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow v-show="isCoverWareHouse" class="justify-content-end px-1">
            <CCol sm="1" class="px-1 align-self-center">
              <label class="col-form-label col-form-label-sm col-sm-12 col-sm-12 text-right px-0">{{$t('label.bl')}}</label>
            </CCol>
            <div class="col-sm-11">
              <div  id="BlSearchWarehouse" class="form-group form-row m-0" style="flex-wrap: nowrap;">
                <v-select
                  id="v-select-small"
                  ref="BlSearchWarehouse"
                  class="form-bl-Search"
                  style="font-size: 11px;"
                  :placeholder="$t('label.select')"
                  :options="BlOption"
                  :reduce="option => option.value" 
                  v-model="BlCargoId"
                  :clearSearchOnSelect="false"
                  :clearable="false"
                  :filterable="false"
                  :dropdownShouldOpen="dropdownShouldOpen"
                  :map-keydown="handlers"
                  @search="(search, loading) => {
                    BlSearchWarehouse = search; 
                    if(search == ''){
                      this.BlCargoId = '';
                    } 
                  }"
                  @input="onInputBlCargoId"
                >
                  <template #no-options="{}">
                      {{$t('label.noResultsFound')}}
                  </template>
                  <template #option="{ Json, label, value }">
                    <div class="w-100 p-1" :class="BlCargoId == value ? 'vs__dropdown-option--highlight' : ''">
                      <cite v-if="value&&Json?.VesselName"><b style="font-weight: bold;">{{ label }}</b> ({{ Json?.TpBlName }}) </cite>
                      <br v-if="value"/>
                      <cite v-if="value&&Json?.VesselName">{{ Json?.VesselName }}{{`(${$t('label.voyage')}: ${Json?.Voyage})`}} - ATA:{{ Json?.Ata ? formatDateTimeWithSlash(Json?.Ata) : 'N/A'}} </cite>
                      <br v-if="value"/>
                      <cite v-if="value&&Json?.MasterBlNro">{{`${$t('label.Master')}: ${Json?.MasterBlNro}`}} </cite>
                    </div>
                  </template>
                </v-select>
                <div class="input-group-append">
                  <CButton
                    size="sm"
                    class="btn-light-watch mr-2"
                    style="padding: 0.15rem 0.4rem;"
                    v-c-tooltip="{
                      content: $t('label.search'),
                      placement: 'top-end'
                    }" 
                    @click="getBlCargoData"
                  >
                    <CIcon name="cil-search"/>
                  </CButton>
                  <CButton
                    color="watch"
                    square
                    size="sm"
                    class="rounded"
                    v-c-tooltip="{
                      content: $t('label.refresh'),
                      placement: 'top-end'
                    }" 
                    @click="RefreshDataBl()"
                    style="padding: 0.15rem 0.4rem;"
                  >
                    <CIcon name="cil-reload"/>
                  </CButton>
                </div>
              </div>
            </div>
          </CRow>
        </CCol>
        <CCol sm="12" lg="2" class="px-1">
          <Locale />
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import autocomplete from '@/components/autocomplete';
import Locale from '@/components/locale.vue';
import { connectionsList } from '@/_helpers/connections';
import { DateFormater, parity } from '@/_helpers/funciones';
import { map } from 'leaflet';
import { timeout } from '@syncfusion/ej2-vue-maps';

//Created:
function created() {
  if(!this.roleSelected)
    this.$router.push({ name: 'RoleSelection' });
}

//MEthods:
async function getYardCargo(item) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardCargo-by-Id', { YardCargoId: item.YardCargoId, YardId: item.YardId })
    .then(response => {
      let res = response.data.data;
      if (res && res?.length>0) {
        this.$store.state.yardManagement.dataContainer = res[0];
        this.$store.state.yardManagement.yardCollapse = 2;
        this.$store.state.yardManagement.isSelect = true;
        setTimeout(() => {
          this.$store.state.yardManagement.isSelect = false;
        }, 10)
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      
    })
}

async function getYardBlCargo(item) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('BlCargo-by-Id', {BlCargoId: item.BlCargoId})
    .then(response => {
      let res = response.data.data;
      if (res && res?.length>0) {
        this.$store.state.yardManagement.dataBl = res[0];
        this.$store.state.yardManagement.yardCollapse = 4;
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
  this.$store.state.yardManagement.loading = false;
}

function childClasses(menus) {
  return {
    'whijo': (Array.isArray(menus) && menus.length < 2)? true : false,
    'whijo-dos': (Array.isArray(menus) && menus.length == 2)? true : false,
    'whijo-tres': (Array.isArray(menus) && menus.length > 2)? true : false
  }
}
function rowQuantity(menus) {
  if(!Array.isArray(menus))
    return 6;

  switch (menus.length) {
    case 1:
      return 12;
    case 2:
      return 6;
    case 3:
      return 4;
    default:
      return 3;
  }
}
function goToSearch(search) {
  this.navegateTo(search.name);
  this.search = '';
}

function isOptionSelected(name) {
  return name == this.$route.name;
}
function toggle() {
  this.toggleInfo = !this.toggleInfo;
}
function hasNoFile(ruta) {
  return ruta == '';
}

function Refreshcontainers() {
  this.$store.state.yardManagement.loading = true;
  this.$http.get('YardCargoReception-by-YardId', { YardId: this.YardId })
    .then(response => {
      let List = response.data.data;
      this.$store.state.yardManagement.globalSelectContainer = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

function SearchResumList() {
  this.$store.state.yardManagement.loading = true;
  this.$http.get('BlCargoResum-list-by-YardId',  { BlCargoJson: {YardId: this.yardData.YardId, SearchKey: this.BlSearchWarehouse} })
    .then(response => {
      let List = response.data.data;
      this.$store.state.yardManagement.globalSelectBl = List&&List.length!=0 ? List : [];
      this.$refs.BlSearchWarehouse.open = true;
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

async function RefreshDataBl(loading = false) {
  this.$store.state.yardManagement.loading = true;
  this.$refs.BlSearchWarehouse.search = '';
  this.BlCargoId = '';
  await this.$http.get('BlCargoResum-list-by-YardId',  { BlCargoJson: {YardId: this.yardData.YardId} })
    .then(response => {
      let List = response.data.data;
      this.$store.state.yardManagement.globalSelectBl = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = loading;
    });
}

function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A';
}

function dropdownShouldOpen(VueSelect) {
  return VueSelect.open;
}

function handlers(map, vm) {
  return {
    ...map,
    13: (e) => {

    }
  }
}

async function getBlCargoData() {
  this.BlCargoId = ''; 

  if(this.BlSearchWarehouse && this.BlSearchWarehouse != '')
    this.SearchResumList();
}

function onInputBlCargoId(event) {
  this.BlCargoId = event;
  this.$store.state.yardManagement.blSelected = event;
}

//Computed:
function loginName() {
  return this.user? this.user.Login : 'User';
}
function levelOneOptions() {
  return this.rutasInfo? this.rutasInfo.LevelOneJson: [];
}
function routesOptions() {
  return this.roleList.map((rol) => Object.assign({}, rol, {
    label: rol.name
  }))
}
function patiosOptions() {
  return this.branchSelected?.YardJson.map((patio) => Object.assign({}, patio, {
    value: patio.YardId,
    label: patio.YardName
  }));
}

function processRoute() {
  return this.$route.meta.process?this.$route.meta.process:'';
}
function manualRoute() {
  return this.$route.meta.manual?this.$route.meta.manual:'';
}

function currentConnectionName() {
  let arr = connectionsList();
  let found = arr.findIndex((item) => item.value == this.apiUrl);
  if(found != -1){
    return `${this.$t('label.connectedTo')}: ${arr[found].label}`;
  }else
    return '';
}

function ContainerOption(){
    if(this.globalSelectContainer.length === 0){
        return [{
            value: '', 
            label: this.$t('label.select'),
            Json: '',
        }];
    }else{
        const chart = [{
            value: '', 
            label: this.$t('label.select'),
            Json: '',
        }];

        this.globalSelectContainer.map(list => {
            chart.push({
                value: list.ContainerId,
                label: list.ContainerCode,
                Json: {...list }
            });
        });
        return chart;
    }
}

function BlOption(){
    const chart = [];

    this.globalSelectBl.map(list => {
        chart.push({
            value: list.BlCargoId,
            label: list.BlNro,
            Json: {...list }
        });
    });
    return chart;
}

function currentLang() {
  return this.$i18n.locale.toUpperCase();
}

function isCoverWareHouse() {
  return this.yardData?.TpYardId == process.env.VUE_APP_YARD_TP_ID_COVER_WAREHOUSE
}

export default {
  name: 'TheNabBar',
  components: {
    autocomplete,
    Locale
  },
  props: {
    navegation: Boolean
  },  
  data () {
    return {
      collapsed: false,
      search: '',
      yard: '',
      patios: [],
      blLocalList: [],
      toggleInfo: false,
      BlSearchWarehouse: '',
      BlCargoId: '',
    }
  },
  created,
  methods: {
    navegateTo (routeName) {
      if(!this.isOptionSelected(routeName))
        this.$router.push({ name: routeName }).catch(() => {
          this.$notify({
            group: 'container',
            title: '¡Modulo Inactivo!',
            text: 'El modulo seleccionado aun no ha sido agregado.',
            type: "error"
          });
        });
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$store.dispatch('connection/delete');
      this.$router.push({ name: 'Login' });
    },
    childClasses,
    rowQuantity,
    goToSearch,
    isOptionSelected,
    toggle,
    hasNoFile,
    getYardCargo,
    Refreshcontainers,
    RefreshDataBl,
    formatDateTimeWithSlash,
    getYardBlCargo,
    dropdownShouldOpen,
    handlers,
    getBlCargoData,
    onInputBlCargoId,
    SearchResumList
  },
  computed: {
    loginName,
    ContainerOption,
    BlOption,
    levelOneOptions,
    routesOptions,
    patiosOptions,
    processRoute,
    manualRoute,
    currentConnectionName,
    currentLang,
    isCoverWareHouse,
    ...mapState({
        user: state => state.auth.user,
        rutasInfo: state => state.menu.routesInfo,
        roleList: state => state.menu.routesByRole,
        roleSelected: state => state.auth.role,
        branchSelected: state => state.auth.branch,
        apiUrl: state => state.connection.apiUrl,
        yardData: state => state.yardManagement.yardData,
        TpYardId: state => state.yardManagement.yardData.TpYardId,
        YardId: state => state.yardManagement.yardData.YardId,
        containerSelected: state => state.yardManagement.containerSelected,
        blSelected: state => state.yardManagement.blSelected,
        globalSelectBl: state => state.yardManagement.globalSelectBl,
        globalSelectContainer: state => state.yardManagement.globalSelectContainer,
        dataBl: state => state.yardManagement.dataBl,
    })
  },
  watch: {
    containerSelected: async function (val) {
      if(val && val!=''){
        this.$store.state.yardManagement.ContainerTabIndex = 0;
        this.$store.state.yardManagement.dropContainerMovement = 0;
        this.$store.state.yardManagement.dropItemInspection = 0;

        let  data = await this.globalSelectContainer.find(item => item.ContainerId == val );
        data && await this.getYardCargo(data);
      }
    },
    blSelected: async function (val) {
      this.$refs.BlSearchWarehouse.open = false;
      if(val && val!=''){

        if(this.$store.state.yardManagement.FgReception || this.$store.state.yardManagement.FgPositioning)
          await this.RefreshDataBl(false);
        
        this.BlCargoId = val;
        let data = await this.globalSelectBl.find(item => item.BlCargoId == val);

        if(data){
          this.$refs.BlSearchWarehouse.search = data?.BlNro ?? '';
          await this.getYardBlCargo(data);

          if(this.$store.state.yardManagement.FgPositioning){
            this.$store.state.yardManagement.BlTabIndex = 5;
            this.$store.state.yardManagement.dropBlMovement = 2;
            this.$store.state.yardManagement.FgPositioning = false;
          }
          else if(this.$store.state.yardManagement.FgReception){
                this.$store.state.yardManagement.BlTabIndex = 0;
                this.$store.state.yardManagement.dropBlMovement = 0;
                this.$store.state.yardManagement.FgReception = false;
              }
          else{
            this.$store.state.yardManagement.BlTabIndex = 0;
            this.$store.state.yardManagement.dropBlMovement = 0;
          }
           //this.$store.state.yardManagement.isSelect = true;
        }
        else{
          await this.RefreshDataBl(false);
          this.$store.state.yardManagement.blSelected = '';
          this.$refs.BlSearchWarehouse.search = '';
          this.$store.state.yardManagement.loading = false;
        }

      }
      else
        this.$refs.BlSearchWarehouse.search = '';
    },
    TpYardId: async function (val) {
      if(val == process.env.VUE_APP_YARD_TP_ID_COVER_WAREHOUSE){
        window.addEventListener('click', (e) => {
          if (document.getElementById('BlSearchWarehouse') && !document.getElementById('BlSearchWarehouse').contains(e.target)) {
            this.$refs.BlSearchWarehouse.open = false;
          }
        })
        document.getElementById('BlSearchWarehouse').addEventListener('keydown', async(e) => {
          if (e.key === 'Enter') {
            this.getBlCargoData();
          }
        })
      }
    },

    
  } 
}
</script>

<style scoped>
  .whijo {
    min-width: 25vw;
  }
  .whijo-dos {
    min-width: 60vw;
  }
  .whijo-tres {
    min-width: 80vw;
  }
  .whijo-cuatro {
    min-width: 100vw;
  }
  .zindex-nav {
    z-index: 1003;
  }

  .after-breadcrumb {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
  .remove-space {
    margin-bottom: 0;
  }
  .active-item {
    text-decoration: none;
    color: #455164;
    background-color: #ebedef;
    border-radius: 5px;
    cursor: default;
  }

.info-link {
  padding: 10px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
a.info-link:hover .info-icon{
  color: rgb(45, 115, 185);
  filter: drop-shadow(0 0 0.30rem #39f);
  transform: scale(1.2);
}

.info-collapse {
  -webkit-transition: width 1.5s ease;
  -moz-transition: width 1.5s ease;
  -o-transition: width 1.5s ease;
  transition: width 1.5s ease;
  
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;

  width: 0px;
}
.info-collapse.in {
  width: 200px;
}
.info-margin {
  margin-right: auto;
}
.btn-info {
  transform: scale(0.9);
}

</style>
